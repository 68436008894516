<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          {{
          $route.params.id == 0
          ? "Nueva medalla"
          : "Medalla: " + $route.params.id
          }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6">
              <CInput label="Nombre" placeholder="Nombre" v-model="form.name" required />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CInput label="Etiqueta" placeholder="Etiqueta" v-model="form.label" />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CSelect label="Rol" placeholder="Rol" v-model="form.role" :options="roleList" required />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CSelect label="Frecuencia" placeholder="Frecuencia" v-model="form.frecuency" :options="frecuencyList"
                required />
            </CCol>
          </CRow>

          <CRow v-if="form.frecuency">
            <CCol xs="12" md="6">
              <CInput label="Fecha de inicio" type="date" required v-model="form.start_date" />
            </CCol>
          </CRow>

          <CRow v-if="form.frecuency && form.frecuency == 'DR'">
            <CCol xs="12" md="6">
              <CInput label="Fecha final" type="date" required v-model="form.end_date"
                :readonly="form.frecuency != 'DR'" />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CSelect label="Tipo" placeholder="Tipo" v-model="form.type" :options="typeList" required />
            </CCol>
          </CRow>

          <CRow v-if="form.type == 'S'" class="mb-2">
            <CCol xs="12" md="6">
              <CInputRadioGroup :options="typeOptionList" :inline="true" :checked.sync="form.type_option" required />
            </CCol>
          </CRow>

          <CRow v-if="form.type == 'S' && form.type_option != null">
            <CCol xs="12" md="6">
              <CInput :label="typeOptionLabel" type="number" :placeholder="typeOptionLabel" v-model="form.value"
                required />
            </CCol>
          </CRow>

          <CRow v-if="form.type == 'S'">
            <CCol xs="12" md="6">
              <CSelect label="Item" placeholder="Item" v-model="form.ItemCode" :options="items" required />
            </CCol>
          </CRow>

          <CRow v-if="form.type == 'C'">
            <CCol xs="12" md="6">
              <CSelect label="Cursos" placeholder="Cursos" v-model="form.course_id" :options="courses" required />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CSelect label="Tipo de premio" placeholder="Tipo de premio" v-model="form.type_reward"
                :options="typeRewardList" required />
            </CCol>
          </CRow>

          <CRow v-if="form.type_reward != null">
            <CCol xs="12" md="6">
              <CInput label="Premio" type="number" placeholder="Premio" v-model="form.reward" required />
            </CCol>
          </CRow>
          
          <CRow>
            <CCol xs="12" md="6">
              <input type="checkbox" v-model="form.showBar" /> Mostrar en progreso
            </CCol>
          </CRow>

        </CCardBody>
        <CCardFooter>
          <CRow class="mt-2">
            <CCol sm="12" md="6">
              <CButton color="primary" @click="goBack">Back</CButton>
            </CCol>
            <CCol sm="12" md="6" class="text-right">
              <CButton color="primary" @click="save" v-if="!loading">Guardar</CButton>
              <div class="text-right" v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast :key="`${item.title}-${index}`" :show="true" :color="item.color" :header="item.title">
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";
import store from '@/store'
import { frecuencies, types, typeOptions, typeRewards, roles } from "./data";

const badgeResource = new Resource("badges");
const itemResource = new Resource("items");

export default {
  name: "Badge",
  data() {
    return {
      loading: false,
      toasts: [],
      opened: null,
      form: {
        name: null,
        label: null,
        frecuency: null,
        start_date: null,
        end_date: null,
        type: null,
        type_option: null,
        value: null,
        ItemCode: null,
        ItemName: null,
        course_id: null,
        type_reward: null,
        reward: null,
        role: null,
      },
      items: [],
      courses: [],
    };
  },
  computed: {
    frecuencyList() {
      return frecuencies;
    },
    typeList() {
      return types;
    },
    typeOptionList() {
      return typeOptions;
    },
    typeRewardList() {
      return typeRewards;
    },
    typeOptionLabel() {
      let label = "";
      const value = this.form.type_option;
      if (value) {
        const typeOption = typeOptions.filter((item) => item.value == value);
        label = typeOption[0].label;
      }
      return label;
    },
    roleList() {
      return [{ label: "Todos", value: null }, ...roles];
    },
  },
  watch: {
    "form.start_date": function (newValue, oldValue) {
      this.calcRange(newValue);
    },
    "form.frecuency": function (newValue, oldValue) {
      if (this.form.start_date) {
        this.calcRange(this.form.start_date);
      }
    },
    "form.ItemCode": function (newValue, oldValue) {
      const item = this.items.map((item) => item.ItemCode == newValue);
      this.form.ItemName = item.ItemName;
    },
  },
  mounted() {
    if (this.$route.params.id != 0) {
      this.getItem();
    }
    this.loadItems();
    this.loadCourses();
  },
  methods: {
    loadCourses() {
      const token = store.state.user.token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL + '/api/course', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          this.courses = response.data.data.map((item) => {
            return {
              label: item.title,
              value: item.id,
            };
          });
          this.courses.push({ label: 'Todos', value: null });
        })
    },
    goBack() {
      this.$router.go(-1);
    },
    async save() {
      this.loading = true;
      try {
        if (this.$route.params.id == 0) {
          const response = await badgeResource.store(this.form);
          if (response.status == 200 && response.data.status == "OK") {
            const { created_at, updated_at, ...data } = response.data.data;
            this.form = data;
            this;
            this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Medalla creada con exito",
            });
          } else {
            throw response.data.message;
          }
        } else {
          const response = await badgeResource.update(
            this.$route.params.id,
            this.form
          );
          if (response.status == 200 && response.data.status == "OK") {
            const { created_at, updated_at, ...data } = response.data.data;
            this.form = data;
            this;
            this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Medalla actualizada con exito",
            });
          } else {
            throw response.data.message;
          }
        }
        this.goBack();
      } catch (error) {
        console.log(error);
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },

    async getItem() {
      this.loading = true;
      try {
        const response = await badgeResource.get(this.$route.params.id);
        if (response.status == 200) {
          const { created_at, updated_at, ...data } = response.data.data;
          this.form = data;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadItems() {
      this.loading = true;
      try {
        const response = await itemResource.list();
        if (response.status == 200) {
          const items = response.data.map((item) => {
            return {
              label: item.ItemName,
              value: item.ItemCode,
            };
          });
          this.items = [{ label: "Todos", value: null }, ...items];
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    calcRange(startDate) {
      if (this.form.frecuency != "DR") {
        const frecuency = frecuencies.filter(
          (item) => item.value == this.form.frecuency
        );
        let endDate = moment(startDate, "YYYY-MM-DD").add(
          frecuency[0].days,
          "days"
        );
        this.form.end_date = endDate.format("YYYY-MM-DD");
      }
    },
  },
};
</script>
